import module from 'module';
import _ from "lodash";
import moment from 'moment';

const templateUrl = require('./individual-data.template.html');
module.component('customerProfileIndividualData', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '='
  },
  controller: function ($scope, $timeout, dict, propertyConfigService) {
    let that = this;

    that.dict = dict;
    that.cfg = propertyConfigService;
    that.uploadFile;

    $scope.$watch('$ctrl.profile.individualData.signatureFileId', () => {
      if (that.profile.individualData) {
        if (that.profile.individualData.signatureFileId) {
          that.profile.individualData.signatureFile = [{
            id: that.profile.individualData.signatureFileId
          }];
        }
      }
    });

    $scope.$watch('$ctrl.profile.pictureFileId', () => {
      if (that.profile.individualData) {
        if (that.profile.pictureFileId) {
          that.profile.pictureFile = [{
            id: that.profile.pictureFileId
          }];
        }
      }
    });

    $scope.$watch('$ctrl.profile.extractedPhones', async () => {
      // initialized if null
      if (!that.profile.extractedPhones.mobile) {
        that.profile.extractedPhones.mobile = { number: '' };
      }
      await that.prepareMobileNumberField();
      that.validateNumberPattern(that.mobileNumber);
    });

    /**
     * Splits and stores mobile number into country prefix and actual individual's phone number
     */
    that.prepareMobileNumberField = async () => {
      that.defaultPrefix = await propertyConfigService.defaultValueAsync('customer', 'contact_data', 'mobile_number');
      const regex = new RegExp('^\\' + that.defaultPrefix);
      if (_.isEmpty(that.profile.extractedPhones.mobile)) {
        that.mobileNumber = '';
      } else {
        that.mobileNumber = that.profile.extractedPhones.mobile.number.replace(regex, '');
      }
    }

    that.validateNumberPattern = (number) => {
      // check if form is ready
      if (!that.form.mobileNumber) {
        return;
      }

      // validate if empty is allowed.
      // allow if mobile number is not required
      if (!that.cfg.required('customer', 'contact_data', 'mobile_number') && _.isEmpty(number)) {
        that.profile.extractedPhones.mobile.number = number;
        that.form.mobileNumber.$setValidity('pattern', true);
        return;
      }
      // otherwise check format
      const regex = new RegExp('^\\'+ that.defaultPrefix +'\\d{10}$');
      const completeMobileNumber = that.defaultPrefix + number;
      const isValid = regex.test(completeMobileNumber);
      if (isValid) {
        that.profile.extractedPhones.mobile.number = completeMobileNumber;
      }
      that.form.mobileNumber.$setValidity('pattern', isValid);
    }

    that.calculateAge = () => {
      // Check if form is ready
      if (!that.form.birthDate) {
        return;
      }

      // Age = date_today - birth_date
      const birthDate = moment(new Date(that.profile.individualData.birthDate));
      const dateToday = moment();
      const age = dateToday.diff(birthDate, 'years');
      const isAgeValid = age > 17;

      that.profile.individualData.age = age;

      that.form.birthDate.$setValidity('validAge', isAgeValid);
      that.form.age.$setValidity('validAge', isAgeValid);
    }
  }
});
