import _ from 'lodash';
import module from 'module';
import BigNumber from 'bignumber.js';
import templateUrl from './item-form.template.html';
import moment from "moment";

module.component('itemForm', {
  templateUrl: templateUrl,
  bindings: {
    pawnTypeId: '=',
    currentItem: '=',
    saveItem: '=',
    createCommandAccess: '=',
    updateCommandAccess: '=',
    hideOldSystemOperationsInput: '=',
    hideCategoryInput: '=',
    hideSellingPriceInput: '=',
    hideValueInput: '=',
    hideDefectsInput: '=',
    excludeKarats: '=',
    itemType: '<'
  },
  controller: function ($scope, $timeout, $route, nav, http, dict, pawnItemTypeCache,
                        pawnItemStoneTypeCache, pawnItemDefectTypeCache, pawnItemAttributeTypeCache,
                        pawnItemStoneColourCache, pawnItemStoneRateCache, pawnMetalTypesCache, pawnProductsCache,
                        accessRuleCache, authentication, systemPropertyCache, propertyConfigService
  ) {

    this.http = http;
    this.dict = dict;
    this.metals = [];
    this.metalComponents = [];
    this.pawnItemTypes = [];
    this.pawnItemDefectTypes = [];
    this.pawnItemAttributeTypes = [];
    this.pawnMetalRates = [];
    this.pawnMetalRateIds = [];
    this.stockedItemMetalRates = [];
    this.stockedItemMetalRateIds = [];
    this.metalRates = [];
    this.metalRateIds = [];
    this.filteredAttributes = [];
    this.pawnItemStoneColours = [];
    this.pawnItemStoneRates = [];
    this.pawnItemStoneTypes = {valuable: [], nonValuable: []};
    this.calculateRequired = true;
    this.cfg = propertyConfigService;

    this.pawnItemId = $route.current.params['itemId'];

    // Defect types allowed for selected category
    this.defectTypes = [];

    this.pawnItemOrigins = [
      {
        label: 'Pawnshop',
        value: 'PAWNSHOP'
      },
      {
        label: 'Others',
        value: 'OTHERS'
      },
    ];

    this.pawnItem = {
      migrated: false,
      weight: null,
      stoneValue: 0,
      plain: false,
      quantity: 1,
      withStones: false,
      metal: {},
      attributes: [],
      stones: {valuable: [], nonValuable: []},
      origin: 'OTHERS'
    };

    this.truncateToFirstDecimalPlace = (val) => {
      const bigNumberVal = new BigNumber(val);
      return bigNumberVal.round(1, BigNumber.ROUND_DOWN).toNumber();
    };

    this.reloadAttributeTypes = (reset = false) => {
      const item = this.pawnItem;
      if (reset) {
        item.attributes = [];
      }

      let attributesIds = [];
      // Collect attributes from CATEGORY
      if (item.category) {
        attributesIds = _.union(attributesIds, item.category.allowedAttributesIds);
      }
      // Collect attributes from TYPE
      if (item.type) {
        attributesIds = _.union(attributesIds, item.type.allowedAttributesIds);
      }
      // Collect attributes from SUBTYPE
      if (item.subtype) {
        attributesIds = _.union(attributesIds, item.subtype.allowedAttributesIds);
      }

      // Fetch attributes by aggregated IDs
      this.filteredAttributes = _.filter(this.pawnItemAttributeTypes, (t) => {
        return _.includes(attributesIds, t.id);
      });
    };

    /**
     * @param c pawn item category
     * @return {Array} of multiselect options suitable for item category
     */
    const getDefectTypesByCategory = (c) => {
      if (!c || !c.allowedDefects) {
        return [];
      }
      const unordered = _.map(c.allowedDefects, (d) => {
        return {label: d.name, value: d.id, checked: d.defaultDefect, orderNo: d.orderNo}
      });
      return _.sortBy(unordered, 'orderNo');
    };

    const getItemDescription = () => {
      if (!this.pawnItem || !this.pawnItem.category) {
        return '';
      }

      const separator = '; ';
      let desc = '';
      const item = this.pawnItem;

      const appendProperty = (property, formatter = null) => {
        // If property is not given -> return description
        if (!property) return desc;
        // Otherwise get property (using formatter if given)
        const prop = formatter ? formatter(property) : property;
        // If property is empty or === 'Other' -> leave with description
        if (!prop || prop.toLowerCase() === 'other') return desc;
        // Otherwise append property
        desc = desc + prop + separator;
      };

      const defectsDescription = () => {
        appendProperty(item.defect, (d) => _.find(this.pawnItemDefectTypes, {id: d.id}).name);
        // Append item defects
        if (item.defectIds) {
          _.forEach(item.defectIds, (d) => {
            const defect = _.find(this.pawnItemDefectTypes, {id: d});
            appendProperty(defect.shortName ? defect.shortName : defect.name);
          });
        }
      };

      const stonesDescription = () => {
        if (!item.stones) {
          return;
        }
        const stones = _.union(item.stones.valuable, item.stones.nonValuable);
        if (stones && stones.length > 0) {
          _.forEach(stones, (s) => {
            appendProperty(s.type, t => t.name);
            appendProperty(s.stoneShapeId, i => _.find(this.dict.PAWN_ITEM_STONE_SHAPE, {id: i}).description);
            // Add stone weight only if [negligible === true]
            if (s.weight && !s.weightNegligible) {
              appendProperty(s.weight, w => this.truncateToFirstDecimalPlace(w) + 'g');
            }
          });
        }
      };

      const extraDescription = () => {
        if (item.attributes) {
          _.forEach(_.filter(item.attributes, (a) => a.type), (a) => {
            const attribute = _.find(this.pawnItemAttributeTypes, {id: a.type.id});
            if (attribute && attribute.printable) {
              appendProperty(attribute.name);
              appendProperty(a.extraType);
              appendProperty(a.value);
            }
          });
        }
      };

      appendProperty(item.category ? item.category.id : null, this.getTypeLabel);
      appendProperty(item.type ? item.type.id : null, this.getTypeLabel);
      appendProperty(item.subtype ? item.subtype.id : null, this.getTypeLabel);
      appendProperty(item.customType);

      if (item.category.category === 'JEWELRY') {
        if (item.metal) {
          appendProperty(item.metal.metalType, (m) => m.name);
        }
        extraDescription();
        if (item.metal && !this.excludeKarats) {
          appendProperty(getKarats(item.metal).join(";"));
        }
        appendProperty(item.remarks);
        stonesDescription();
      } else {
        extraDescription();
        appendProperty(item.remarks);
      }

      if (!this.hideDefectsInput) {
        defectsDescription();
      }
      appendProperty(item.weight, (w) => 'appx.' + this.truncateToFirstDecimalPlace(w) + 'gr');

      return desc;
    };

    const getFinenessByRateId = (metalRateId) => {
      const metalRate = this.metalRates.find(metalRate => metalRate.id === metalRateId);
      return metalRate ? metalRate.fineness : "-";
    };

    const getKarats = (metal) => {
      if (!metal.components || metal.components.length === 0) {
        return metal.metalRateId ? [getFinenessByRateId(metal.metalRateId)] : []
      }
      return metal.components.reduce((prev, curr) => prev.concat(getKarats(curr)), []);
    };

    this.getTypeByLevel = (level) => {
      switch (level) {
        case 0:
          // For CATEGORY (level == 0) -> read pawn item type directly
          return _.filter(this.pawnItemTypes, {level: 0});
        case 1:
          // For TYPE (level === 1) -> read children of selected category
          if (!this.pawnItem.category) {
            return [];
          }
          return _.filter(this.pawnItemTypes, {level: 1, parentId: this.pawnItem.category.id});
        case 2:
          // For SUBTYPE (level === 2) -> read children of selected type
          if (!this.pawnItem.type) {
            return [];
          }
          return _.filter(this.pawnItemTypes, {level: 2, parentId: this.pawnItem.type.id});
      }
    };

    this.getTypeLabel = (typeId) => {
      const type = _.find(this.pawnItemTypes, {id: typeId});
      return type && type.name ? type.name : 'N/A';
    };

    const setupItem = async () => {
      const item = this.currentItem;
      if (!item) {
        await setupAvailablePawnMetalRatesNow();
        return;
      }

      this.isEdit = true;
      
      // Extract basic properties
      const current = {
        ...item,
        id: Number(item.id),
        migrated: item.migrated,
        category: _.find(this.pawnItemTypes, {id: item.categoryId}),
        type: _.find(this.pawnItemTypes, {id: item.typeId}),
        subtype: item.subtypeId ? _.find(this.pawnItemTypes, {id: item.subtypeId}) : null,
        withStones: !item.plain,
        stones: {valuable: [], nonValuable: []},
        origin: item.origin,
        files: [],
        karatsDescription: ''
      };

      // If file ids are given -> load product metadata
      current.files = _.map(item.fileIds, fid => ({id: fid}));

      // Extend metal with type
      if (current.metal) {
        Object.assign(current.metal, {metalType: _.find(this.metals, {id: current.metal.metalTypeId})})
        // If metal is composed -> extend components
      }

      // Extract attributes (aka extra description)
      if (current.attributes) {
        _.forEach(current.attributes, (a) => {
          Object.assign(a, {type: _.find(this.pawnItemAttributeTypes, {id: a.attributeTypeId})});
        })
      }

      // Extract stones
      if (item.stones) {
        _.forEach(item.stones, (s) => {
          // Read stone type
          let type = null;
          if (s.stoneTypeId) {
            type = _.find(this.pawnItemStoneTypes.valuable, {id: s.stoneTypeId}) || _.find(this.pawnItemStoneTypes.nonValuable, {id: s.stoneTypeId});
          }
          Object.assign(s, {
            type: type
          });

          // Read stone colour
          Object.assign(s, {stoneColour: s.stoneColourId ? _.find(this.pawnItemStoneColours, {id: s.stoneColourId}) : null});

          // Read stone rate
          Object.assign(s, {stoneRate: s.stoneRateId ? _.find(this.pawnItemStoneRates[s.stoneTypeId], {id: s.stoneRateId}) : null});

          // Rewrite stones to pawn item
          if (s.type.valuable) {
            current.stones.valuable.push(s);
          } else {
            current.stones.nonValuable.push(s);
          }
        });
      }

      // Filter defect types allowed for selected category and map them to multiselect model
      this.pawnItem = current;

      this.defectTypes = getDefectTypesByCategory(current.category);
      this.reloadAttributeTypes();
      await setupAvailablePawnMetalRatesWhenValued();
      this.setupMetalComponents();
      this.pickSelectedFinenessForOverriddenOrLastMetalRate();
      this.pickSelectedFinenessForComponents();
    }

    $scope.$watch('$ctrl.currentItem', async () => {
      if (!this.currentItem) {
        return;
      }
      await setupItem();
    });

    const setupAvailablePawnMetalRatesWhenValued = async () => {
      const valuedOnDate = moment(this.pawnItem.valuedOn).format('YYYY-MM-DD');
      this.metalRates = await this.http.get(`/products/pawns/metal-rates?systemDate=${valuedOnDate}&type=${this.itemType}`).toPromise();
      this.metalRateIds = this.metalRates.map(r => r.id);
    }

    const setupAvailablePawnMetalRatesNow = async () => {
      this.metalRates = await this.http.get(`/products/pawns/metal-rates?type=${this.itemType}`).toPromise();
      this.metalRateIds = this.metalRates.map(r => r.id);
    }

    const setupPawnItemTypes = (itemTypes) => {
      this.pawnItemTypes = itemTypes;
      const types = _.filter(this.pawnItemTypes, {level: 1});
      _.forEach(types, (type) => {
        let parentType = _.find(this.pawnItemTypes, {id: type.parentId});
        Object.assign(type, {fullName: '(' + parentType.name + ') ' + type.name});
      });
    }

    const setupAllowedCategories = () => {
      const allCategories = this.getTypeByLevel(0);

      if (!this.pawnType) {
        this.allowedCategories = allCategories;
        return;
      }

      if (this.pawnType.restrictPawnItemCategories) {
        const allowedCategoryIds = this.pawnType.allowedPawnItemCategoryIds
        this.allowedCategories = allCategories.filter((c) => allowedCategoryIds.includes(c.id));
      }
    };
    setupAllowedCategories();
    $scope.$watch("$ctrl.pawnTypeId", async () => {
      if (!this.pawnTypeId) {
        return;
      }
      setupPawnItemTypes(await pawnItemTypeCache.toPromise());

      const pawnTypes = await pawnProductsCache.toPromise();
      this.pawnType = pawnTypes.find((pawnType) => Number(pawnType.id) === Number(this.pawnTypeId));

      setupAllowedCategories();
      await setupItem();
    });

    this.reloadCategory = () => {
      // Filter defect types allowed for selected category and map them to multiselect model
      this.defectTypes = getDefectTypesByCategory(this.pawnItem.category);
      // Reset item properties
      this.pawnItem = {
        id: this.pawnItem.id,
        migrated: this.pawnItem.migrated,
        requestAmount: this.pawnItem.requestAmount,
        category: this.pawnItem.category,
        type: null,
        subtype: null,
        defectIds: [],
        quantity: 1,
        attributes: [],
        remarks: null,
        files: this.pawnItem.files,
        origin: this.pawnItem.origin
      };
      // Set default item defects
      _.forEach(_.filter(this.defectTypes, {checked: true}), (d) => {
        this.pawnItem.defectIds.push(d.value);
      });
      // If category = JEWELRY -> create additional fields
      if (this.pawnItem.category === 'JEWELRY') {
        Object.assign(this.pawnItem, {
          weight: null,
          metal: {},
          stoneValue: 0,
          plain: false,
          withStones: false,
          stones: {valuable: [], nonValuable: []},
        });
      }
      // Reload attributes types
      this.reloadAttributeTypes(true);
    };

    this.getFinenessByMetal = (metalTypeId) => {
      return _.filter(this.metalRates, (r) => _.includes(r.metalTypeIds, metalTypeId));
    };

    this.customTypeRequired = () => {
      const item = this.pawnItem;
      // If CATEGORY or TYPE or SUBTYPE is custom -> custom type is required
      return (item.category && item.category.custom)
        || (item.type && item.type.custom)
        || (item.subtype && item.subtype.custom);
    };

    this.onMetalTypeUpdate = () => {
      const metalType = this.pawnItem.metal.metalType;
      this.pawnItem.metal = {
        metalType: metalType,
        metalTypeId: metalType.id,
        percentage: 100
      };
      // If metal type is [combined] -> create components
      if (metalType.combined) {
        this.pawnItem.metal.components = [];
        this.pawnItem.metal.percentage = 0;
        for (let i = 0; i < metalType.componentsCount; i++) {
          this.pawnItem.metal.components.push({
            metalTypeId: null,
            percentage: 0,
            weight: 0,
            metalValuation: 0
          })
        }
      }
      // Recalculate metal weight
      this.onWeightUpdate();
      this.requireCalculate();
    };

    this.onMetalTypeComponentUpdate = (m) => {
      m.metalRateId = undefined;
      this.onMetalComponentUpdate(m)
    };

    this.onMetalComponentUpdate = (m, field) => {
      // Update specific metal component
      const metalWeight = this.pawnItem.metal.weight;
      m.metalValuation = undefined;

      if (field === 'percentage') {
        m.weight = new BigNumber(metalWeight)
          .times(m.percentage)
          .div(100)
          .round(2)
          .toNumber();
      } else if (field === 'weight') {
        m.percentage = new BigNumber(m.weight)
          .div(metalWeight)
          .times(100)
          .round(1)
          .toNumber();
      }

      const components = this.pawnItem.metal.components;
      this.calculatedMetalWeight = new BigNumber(_.sumBy(components, 'weight'))
        .round(2)
        .toNumber();
      // Update root metal
      this.pawnItem.metal.percentage = new BigNumber(this.calculatedMetalWeight)
        .div(metalWeight)
        .times(100)
        .round(1)
        .toNumber();

      this.requireCalculate();
    };

    this.setupMetalComponents = () => {
      const metal = this.pawnItem.metal;
      if (!metal) {
        return;
      }
      if (metal.metalType && metal.metalType.combined) {
        _.forEach(metal.components, (c) => this.onMetalComponentUpdate(c));
      }
    };

    this.onWeightUpdate = () => {
      const item = this.pawnItem;
      if (item && item.weight) {
        let weight = item.weight;
        // If item is with stones -> reduce metal weight by weight of non valuable stones
        if (item.withStones && item.stones.nonValuable) {
          let stonesWeight = 0;
          _.forEach(item.stones.nonValuable, (s) => {
            if (s.weight && !s.weightNegligible) {
              stonesWeight += s.weight;
            }
          });
          weight = new BigNumber(weight).sub(stonesWeight).round(2).toNumber();
        }

        if (this.pawnItem.metal) {
          this.pawnItem.metal.weight = weight;
        }
      }
      // Calculate metal valuation
      this.setupMetalComponents();
      this.requireCalculate();
    };

    this.initWithStones = () => {
      if (!this.allowStonePredicatesSet || this.pawnItem.withStones != null) {
        return;
      }
      this.pawnItem.withStones = this.allowStonePredicatesSet.has(true) /* with stones */ || this.allowStonePredicatesSet.has(undefined) /* both options */;
      this.onWithStonesUpdate();
    }

    this.onWithStonesUpdate = () => {
      this.resetStones();
      if (this.pawnItem.withStones) {
        this.addStone(true);
      }
    };

    this.resetStones = () => {
      this.pawnItem.stones = {valuable: [], nonValuable: []};
    }

    this.onStoneNegligibleUpdate = (valuable, idx) => {
      const stonesList = valuable ? this.pawnItem.stones.valuable : this.pawnItem.stones.nonValuable;
      if (stonesList[idx]) {
        let weight = 0;
        const stone = stonesList[idx];
        if (stone && !stone.weightNegligible) {
          weight = 1;
        }
        stonesList[idx].weight = weight;
        this.onWeightUpdate();
      }
      this.requireCalculate();
    };

    this.onStoneTypeUpdate = (valuable, idx) => {
      // Reset stone shape
      const stonesList = this.pawnItem.stones.valuable;
      if (stonesList[idx]) {
        const stoneType = stonesList[idx].type;
        let stoneShapeId = null;
        if (stoneType && stoneType.shapeSupported && stoneType.supportedShapes.length === 1) {
          stoneShapeId = stoneType.supportedShapes[0].id;
        }
        stonesList[idx].stoneShapeId = stoneShapeId;
      }
      this.requireCalculate();
    };

    this.addAttribute = () => {
      this.pawnItem.attributes.push({id: 0, itemId: this.pawnItemId});
    };

    this.onAttributeTypeChange = (attr) => {
      attr.attributeTypeId = attr.type.id;
    }

    this.removeAttribute = (idx) => {
      this.pawnItem.attributes = _.reject(this.pawnItem.attributes, (value, index) => idx === index);
    };

    this.addStone = (valuable) => {
      const stonesList = valuable ? this.pawnItem.stones.valuable : this.pawnItem.stones.nonValuable;
      // Find default stone colour
      // Default colour = first colour with 0 diminishing factor
      let defaultColour = null;
      if (valuable && this.pawnItemStoneColours) {
        defaultColour = _.head(_.filter(this.pawnItemStoneColours, (c) => c.diminishingFactor === 0));
      }
      // Create new stone with default properties
      stonesList.push({
        id: 0,
        quantity: 1,
        flawless: false,
        stoneColour: defaultColour,
        systemValuation: null,
        valuation: valuable ? null : 0,
        weightNegligible: true,
        weight: valuable ? 0 : 0.0
      });
    };

    this.removeStone = (valuable, idx) => {
      const remove = (stonesList) => _.reject(stonesList, (value, index) => idx === index);
      if (valuable) {
        this.pawnItem.stones.valuable = remove(this.pawnItem.stones.valuable);
      } else {
        this.pawnItem.stones.nonValuable = remove(this.pawnItem.stones.nonValuable);
      }
      this.requireCalculate();
    };

    this.enableSubmitButton = () => {
      const valuated = this.pawnItem.valuation || this.pawnItem.sellingPrice;
      const formValid = !this.appraisalForm.$invalid;

      if (this.isJewelry()) {
        return formValid && valuated && !this.calculateRequired;
      }
      return formValid && valuated;
    }

    this.pickSelectedFinenessForOverriddenOrLastMetalRate = () => {
      if (_.isEmpty(this.metalRates) || !this.pawnItem.metal || !this.pawnItem.metal.rate) {
        return;
      }

      const sameFinenessMetalRate = _.find(this.metalRates, {fineness: this.pawnItem.metal.rate.fineness});
      if (!sameFinenessMetalRate) {
        return;
      }

      if (this.pawnItem.metal.rate.overridden) {
        // UI purposes. If the metal rate is overridden, there is no way to show the selection in the UI naturally.
        // Find the same fineness and use this to populate the dropdown list
        // Note: it shouldn't affect metal value because that ID will be replaced again but with the original one
        this.pawnItem.metal.metalRateId = sameFinenessMetalRate.id;
      } else if (!this.metalRateIds.includes(this.pawnItem.metal.rate.id)) {
        // Pick the latest one available for the given date, affects metal value
        this.setSelectedPawnMetalRate(sameFinenessMetalRate.id);
        this.pawnItem.metal.metalRateId = sameFinenessMetalRate.id;
      }
    }

    this.pickSelectedFinenessForComponents = () => {
      const metal = this.pawnItem.metal;
      if (_.isEmpty(this.metalRates) || !metal || !metal.metalType || !metal.metalType.combined) {
        return;
      }

      _.forEach(metal.components, (c) => {
        if (!c.rate) {
          return;
        }

        const sameFinenessMetalRate = _.find(this.metalRates, {fineness: c.rate.fineness});
        if (!sameFinenessMetalRate) {
          return;
        }

        if (!this.metalRateIds.includes(c.rate.id)) {
          // Pick the latest one available for the given date, affects metal value
          c.rate = sameFinenessMetalRate;
          c.metalRateId = sameFinenessMetalRate.id;
        }
      });
    }

    const subscription = pawnItemTypeCache.toObservable()
      .combineLatest(systemPropertyCache.toObservable(), (itemTypes, systemProperties) => {
        this.systemProperties = systemProperties;
        this.canOverridePawnMetalRate = _.find(systemProperties, {code: 'OVERRIDE_PAWN_METAL_RATE_ENABLED'}).value === "TRUE";
        return itemTypes;
      })
      // Read pawn item defect types
      .combineLatest(pawnItemDefectTypeCache.toObservable(), (itemTypes, defectTypes) => {
        this.pawnItemDefectTypes = defectTypes;
        return itemTypes;
      })
      // Read pawn item attributes types
      .combineLatest(pawnItemAttributeTypeCache.toObservable(), (itemTypes, attributeTypes) => {
        this.pawnItemAttributeTypes = attributeTypes;
        return itemTypes;
      })
      // Read pawn item stone types
      .combineLatest(pawnItemStoneTypeCache.toObservable(), (itemTypes, stoneTypes) => {
        if (stoneTypes && stoneTypes.length > 0) {
          // Filter valuable stone types
          this.pawnItemStoneTypes.valuable = _.filter(stoneTypes, {valuable: true});
          // Assign supported shapes (only for types supporting stone shapes
          _.forEach(_.filter(this.pawnItemStoneTypes.valuable, t => t.shapeSupported === true), (t) => {
            t.supportedShapes = _.filter(this.dict.PAWN_ITEM_STONE_SHAPE, s => _.includes(t.supportedShapeIds, s.id));
          });
          // Filter non-valuable stone types
          this.pawnItemStoneTypes.nonValuable = _.filter(stoneTypes, {valuable: false});
        }
        return itemTypes;
      })
      .combineLatest(pawnItemStoneColourCache.toObservable(), (itemTypes, stoneColours) => {
        this.pawnItemStoneColours = stoneColours;
        return itemTypes;
      })
      .combineLatest(pawnItemStoneRateCache.toObservable(), (itemTypes, stoneRates) => {
        // Group stone rates by stone type id
        if (stoneRates && stoneRates.length > 0) {
          this.pawnItemStoneRates = _.groupBy(stoneRates, 'stoneTypeId');
          // Extract stone types with rates
          const stoneTypeIds = _.uniq(_.map(stoneRates, (r) => r.stoneTypeId));
          // Sort rates in every bucket (bucket is identified by stone type id)
          _.forEach(stoneTypeIds, (id) => {
            const unordered = this.pawnItemStoneRates[id];
            this.pawnItemStoneRates[id] = _.sortBy(unordered, (r) => r.name);
          });
        }
        return itemTypes;
      })
      .combineLatest(pawnMetalTypesCache.toObservable(), (itemTypes, metals) => {
        this.metals = metals;
        this.metalComponents = _.filter(this.metals, {combined: false});
        return itemTypes;
      })
      .combineLatest(accessRuleCache.toObservable(), (itemTypes, accessRules) => {
        const createPawnAccessRules = accessRules.filter(ar => ar.command === 'CreatePawn' && authentication.context.roleIds.includes(ar.roleId));
        this.allowStonePredicatesSet = new Set(createPawnAccessRules.map(ar => ar.predicates['HAS_STONE']));
        this.allowStoneChange = this.allowStonePredicatesSet.size > 1 || this.allowStonePredicatesSet.has(undefined);
        return itemTypes;
      })
      .combineLatest(systemPropertyCache.toObservable(), (itemTypes, systemProperties) => {
        this.stockedItemMarkupPercentage = _.find(systemProperties, {code: 'STOCKED_ITEM_REPRICING_MARKUP_PERCENTAGE'}).value;
        return itemTypes;
      })
      // Read pawn item types
      .subscribe(async (itemTypes) => {
        setupPawnItemTypes(itemTypes);
        setupAllowedCategories();
        await setupItem();
      });

    this.back = () => {
      // If [id] is given item is edited and we have to go back by 2 steps
      // Otherwise item is created and only 1 step is required
      nav.back(this.pawnItem.id ? 2 : 1);
    };

    const setMetalsRequest = (request) => {
      const components = this.pawnItem.metal.components;

      // For pure metal
      if (!components || !components.length) {
        const isValuable = this.pawnItem.metal.metalType.valuable;
        const rate = _.find(this.metalRates, {id: this.pawnItem.metal.metalRateId});
        request.metal.pricePerGram = isValuable ? rate.pricePerGram : 0;

        const overriddenMetalRate = this.pawnItem.metal.rate;
        if (overriddenMetalRate && overriddenMetalRate.overridden) {
          request.metal.pricePerGram = overriddenMetalRate.pricePerGram;
        }

        return;
      }

      // For combined metal
      request.metal.components = components.map((component) => {
        const rate = _.find(this.metalRates, {id: component.metalRateId});

        return {
          metalWeight: this.pawnItem.weight,
          percentage: component.percentage,
          pricePerGram: rate ? rate.pricePerGram : 0
        };
      });
    };

    const setValuableStonesRequest = (request) => {
      if (!this.pawnItem.stones) {
        return;
      }

      const stones = this.pawnItem.stones.valuable;
      if (!stones || !stones.length) {
        return;
      }

      request.valuableStones = stones.map((stone) => {
        const valuableStone = {
          quantity: stone.quantity,
          rate: stone.stoneRate.value,
          flawless: stone.flawless
        }
        if (stone.valuation) {
          valuableStone.userValuation = stone.valuation;
        }
        if (stone.stoneColour) {
          valuableStone.diminishingFactor = stone.stoneColour.diminishingFactor
        }
        return valuableStone;
      });
    };

    const setNonValuableStonesRequest = (request) => {
      const stones = this.pawnItem.stones.nonValuable;
      if (!stones || !stones.length) {
        return;
      }
      request.metal.significantNonValuableStoneTotalGrams =
        stones.reduce((prev, next) => (prev + next.weight), 0);
    };

    this.calculate = async () => {
      const request = {
        repriceStockedItem: this.repriceStockedItems,
        metal: {
          weight: this.pawnItem.weight
        }
      };

      setMetalsRequest(request);
      setValuableStonesRequest(request);
      setNonValuableStonesRequest(request);

      const result = await http.post('/products/pawns/calculate-item', request).toPromise();
      this.pawnItem.valuation = result.valuation;
      this.pawnItem.stoneValue = result.valuableStonesValuation;
      this.pawnItem.metal.weight = result.metal.weight || this.pawnItem.metal.weight;
      this.pawnItem.metal.metalValuation = result.metal.valuation;

      if (this.pawnItem.metal.components) {
        this.pawnItem.metal.components.forEach((component, i) => {
          component.metalValuation = result.metal.components[i].valuation;
          component.weight = result.metal.components[i].weight;
        });
      }

      if (this.pawnItem.withStones && this.pawnItem.stones.valuable) {
        this.pawnItem.stones.valuable.forEach((stone, i) => {
          const newSystemValuation = result.valuableStones[i].valuation;

          const emptyUserValuation = stone.valuation === undefined || stone.valuation === null;
          if (emptyUserValuation || stone.valuation === stone.systemValuation) {
            stone.valuation = newSystemValuation;
          }

          stone.systemValuation = newSystemValuation;
        });
      }

      this.calculateRequired = false;
    };

    this.requireCalculate = (index) => {
      this.calculateRequired = true;

      if (!this.pawnItem.stones || !this.pawnItem.stones.valuable || !this.pawnItem.stones.valuable[index]) {
        return;
      }

      // Reset current valuation in order to force recalculation of this and any other dependant values
      this.pawnItem.stones.valuable[index].valuation = null;
    }

    this.enableCalculateItem = () => {
      if (!this.pawnItem.metal) {
        return false;
      }

      let allowCalculate = false;

      // Combined metal
      if (!_.isEmpty(this.pawnItem.metal.components)) {
        // must have at least 1 valuable metal to be true
        allowCalculate = this.pawnItem.metal.components.some((c) => this.metalRateIds.includes(c.metalRateId));

      // Single metal
      } else if (this.pawnItem.metal.metalType) {
        if (this.pawnItem.metal.metalType.valuable) {
          allowCalculate = this.metalRateIds.includes(this.pawnItem.metal.metalRateId);
        } else {
          allowCalculate = true;
        }
      }

      return this.pawnItem.weight && allowCalculate && this.calculateRequired;
    };

    this.save = () => {
      if (this.pawnItem.metal && !this.excludeKarats) {
        this.pawnItem.karatsDescription = getKarats(this.pawnItem.metal).join(';');
      }
      this.saveItem && this.saveItem(this.pawnItem);
    };

    this.isJewelry = () => {
      return this.pawnItem && this.pawnItem.category
        && this.pawnItem.category.category === 'JEWELRY';
    }

    this.isJewelryAndHasStones = () => {
      return this.isJewelry() && this.pawnItem.withStones;
    }

    this.setSelectedPawnMetalRate = (id) => {
      if (!id) {
        return;
      }

      let overrideCheckbox = false;

      if (this.pawnItem.metal.rate) {
        overrideCheckbox = this.pawnItem.metal.rate.overridden;
      }

      if (this.pawnItem.metal.rate && id != this.pawnItem.metal.rate.id) {
        overrideCheckbox = false;
      }

      this.pawnItem.metal.rate = Object.assign({}, _.find(this.metalRates, {id: id}));
      this.pawnItem.metal.rate.overridden = overrideCheckbox;
    }

    // On init set form as submitted to highlight invalid fields
    $timeout(() => {
      this.appraisalForm.$setSubmitted();
    });

    // With every pawn item data change -> update item description & valuation
    $scope.$watch('$ctrl.pawnItem', () => {
      this.pawnItem.autoDescription = getItemDescription();
    }, true);
    $scope.$watch('$ctrl.pawnItem.metal', () => {
      this.pawnItem.autoDescription = getItemDescription();
    }, true);

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }
});
