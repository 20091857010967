import module from 'module';
import _ from 'lodash';
import './pawn-product-general.style.less';

const templateUrl = require('./pawn-product-general.template.html');

class PawnProductGeneralForm {
  constructor(pawnTagCache) {
    this.pawnTagCache = pawnTagCache;
  }

  async $onInit() {
    await this.pawnTagCache.toPromise().then(t => this.tags = t);

    this.termIntervals = [
      {
        value: 'DAILY',
        label: 'Daily'
      },
      {
        value: 'MONTHLY',
        label: 'Monthly'
      }
    ];
  }

  getTermIntervalAppendLabel() {
    return this.pawnProduct.termInterval === 'DAILY' ? 'days' : 'months';
  }

}

module.component('pawnProductGeneralForm', {
  templateUrl,
  bindings: {
    pawnProduct: '=',
    form: '=',
    createMode: '<',
    branches: '<',
    pawnItemCategories: '<'
  },
  controller: PawnProductGeneralForm
});