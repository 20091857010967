import module from "module";
import CompositeRequest from 'shared/common/compositeRequest';
import _ from 'lodash';
import {productAvailability} from 'constants/productDefinition';

const templateUrl = require('./pawn-product-details.template.html');
module.component('pawnProductDetails', {
  templateUrl,
  controller: function (pawnProductsCache, $route, $location, http, notification, confirmation, breadcrumbs, branchService, pawnItemTypeCache) {
    const that = this;

    that.$onInit = async () => {
      that.branches = await branchService.toPromise();
      const pawnItemTypes = await pawnItemTypeCache.toPromise();
      that.pawnItemCategories = _.filter(pawnItemTypes, {level: 0});
    };

    that.pawnProduct = {
      productDefinition: {
        productAvailability: productAvailability.allBranches.value,
        availableInBranchIds: [],
        expired: false,
        daysInYear: 360,
        productGroup: "PAWN"
      },
      cycleCounting: false,
      applicationFee: {"rate": 0, "type": "FIXED_AMOUNT"},
      serviceCharge: {"rate": 0, "type": "FIXED_AMOUNT"},
      penalty: {"rate": 0, "type": "FIXED_AMOUNT"},
      interbranchRenewalCharge: {"rate": 0, "type": "FIXED_AMOUNT"},
      docStampFee: {
        initialPayment: 40,
        chargeForEachStep: 20,
        stepValue: 5000,
        doChargeFirstStep: true
      },
      minAmount: 100,
      maxAmount: 1000000000,
      maxTermsToExpire: 4,
      maxAdditionalAmount: 0,
      term: 31,
      termInterval: 'DAILY',
      gracePeriod: 0,
      defaultInterestRate: 400,
      pastDueInterestRate: 400,
      interestPaidInAdvance: true,
      uidLedgerEnabled: false,
      restrictPawnItemCategories: false,
      allowedPawnItemCategoryIds: [],
      movePawnItemsToStockOnExpiration: false,
      defaultEirRate:0
    };

    let productId = $route.current.params['productId'];
    that.createMode = !productId;

    let s = null;
    if (!that.createMode) {
      s = pawnProductsCache.toObservable().subscribe(data => {
        that.pawnProduct = _.find(data, (d) => d.id == productId);
        _.set(breadcrumbs, 'options.pawn-product-label', this.pawnProduct.productDefinition.productName);
      });
    }


    this.cancel = () => {
      const parent = '/admin/pawn-products';
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => $location.path(parent));
    };

    this.confirmChanges = () => {
      if (this.pawnProduct.docStampFee.doChargeFirstStep == null) { //Check if it set
        this.pawnProduct.docStampFee.doChargeFirstStep = false;
      }
      if (that.createMode) {
        http.post(`/products/pawns/types`, this.pawnProduct).success(() => {
          notification.show('Pawn product created successfully');
          pawnProductsCache.refetch();
          $location.path('/admin/pawn-products');
        }).error((err) => {
          notification.show('Error', 'Could not create pawn product');
        });
      } else {
        const typeChanges = !(this.generalForm.$pristine && this.chargesForm.$pristine);
        const pawnTypeRequest = !typeChanges ? null :
          http.put(`/products/pawns/types/${this.pawnProduct.id}`, this.pawnProduct).success(() => pawnProductsCache.refetch());
        const saveRequest = this.glMappingComponent.save();

        const rq = CompositeRequest.create()
          .add(pawnTypeRequest)
          .add(saveRequest);

        if (pawnTypeRequest || saveRequest) {
          rq.success(function () {
            notification.show("Success", "pawn Product updated successfully");
            pawnProductsCache.refetch();
            $location.path('/admin/pawn-products');
          })
        } else {
          notification.show("Information", "No changes have been made");
          $location.path('/admin/pawn-products');
        }
      }
    };

    this.setGlMappingComponent = (instance) => {
      this.glMappingComponent = instance;
    };

    that.$onDestroy = () => {
      if (s) s.unsubscribe();
    };
  }
});
