// polyfills
import "babel-polyfill";

// analytics
import './analytics/analytics.service';

// vendor imports
import 'angular';
import 'lodash';
import $ from 'jquery';
// application imports
import module from 'module';
import 'config';
// shared
import 'shared';

import 'components/common/refresh-button/refresh-button.component';
import 'components/common/date-range-picker/date-range-picker.component';
import 'components/common/file-upload/file-upload.component';
import 'components/common/file-upload/file-upload-new.component';
import 'components/common/file-upload/file-upload-webcam.component';
import 'components/common/file-upload/validate-uploaded.directive';
import 'components/common/webcam/webcam.component';
import 'components/common/filters/tree-filter.component';
import 'components/common/filters/branch-filter.component';
import 'components/common/filters/report-filters.component';
import 'components/common/help/help.component';

// common technical components
import 'components/technical/index';
// menu
import 'components/menu/menu.component';
// general ledger
import 'components/general-ledger/accounts/gl-account-details.component';
import 'components/general-ledger/accounts/gl-accounts.component';
import 'components/general-ledger/transactions';
import 'components/general-ledger/common/gl-account-list.component';
import 'components/general-ledger/common/gl-header.component';
import 'components/general-ledger/common/gl-account.service';
import 'components/general-ledger/common/gl-ledger.service';
import 'components/general-ledger/common/gl-transaction.service';
// administration
import 'components/administration/loan/index.js';
import 'components/administration/pawn/index.js';
import 'components/administration/layaway/index.js';
// administration/term-deposit
import 'components/administration/term-deposit/list/term-deposit-list.component';
import 'components/administration/term-deposit/details/term-deposit-details.component';
import 'components/administration/term-deposit/section/term-deposit-section.component';
import 'components/administration/term-deposit/section/term-deposit-fees.component';
import 'components/administration/term-deposit/common/term-deposit-service';
// administration/deposit-account
import 'components/administration/deposit-account/list/deposit-account-list.component';
import 'components/administration/deposit-account/details/deposit-account-details.component';
import 'components/administration/deposit-account/section/deposit-account-section.component';
import 'components/administration/deposit-account/common/deposit-account-service';
// administration/gl-mappings
import 'components/administration/gl-mappings/gl-mappings.component';
import 'components/administration/gl-mappings/gl-mappings-service';
// administration/casa/interest-board
import 'components/administration/casa/interest-board/interest-board.component';
import 'components/administration/casa/interest-board/interest-board.service';
// administration/users
import 'components/administration/users/branches/users-branches.component';
import 'components/administration/users/list/users-list.component';
import 'components/administration/users/filtered-list/users-filtered-list.component';
import 'components/administration/users/details/users-details.component';
import 'components/administration/users/change-branch/users-change-branch.component';
import 'components/administration/user-logins/user-login-record.component';
import 'components/administration/user-logins/logged-in/logged-in-users.component';
import 'components/administration/user-logins/shift-management/shift-list.component';
import 'components/administration/user-logins/user-attendance/user-attendance.component';
// administration/holiday
import 'components/administration/holiday/holiday-list.component';
import 'components/administration/holiday/holiday-branches.component';
// administration/prints
import 'components/administration/prints/prints-list.component';
import 'components/administration/prints/prints-details.component';
import 'components/administration/prints/prints-customization.component';
import 'components/administration/prints/prints-page.component';
// administration/transactions
import 'components/administration/transactions/transactions-list.component';
import 'components/administration/transactions/transactions-details.component';
import 'components/administration/transactions/categories/transaction-categories.component'
import 'components/administration/transactions/transaction-category.service'
// administration/metals
import 'components/administration/metals/pawned-item-metal-rates.component';
import 'components/administration/metals/stocked-item-metal-rates.component';
// administration/stones
import 'components/administration/stones/stones.component';
// administration/pawn-auctions
import 'components/administration/pawn-auction/pawn-auction-branches.component';
import 'components/administration/pawn-auction/pawn-auction-list.component';
// administration/forex
import 'components/administration/forex';
// administration/administration-branches
import 'components/administration/administration-branches/administration-branches.component';
import 'components/administration/administration-branches/branch-details/branch-details.component';
// administration/areas
import 'components/administration/areas';
// administration/depository-account
import 'components/administration/depository-account/list/depository-account-list.component';
import 'components/administration/depository-account/details/depository-account-details.component';
// administration/organization
import 'components/administration/organization/organization.component';
// administration/general-ledgers
import 'components/administration/general-ledger/general-ledger.component';
import 'components/administration/general-ledger/transaction-mapping/gl-transaction-mapping.component';
// administration/rates
import 'components/administration/rates/pawned-item-rates.component';
import 'components/administration/rates/stocked-item-rates.component';

import 'components/customer/loans/automatic-transfer-arrangement';
import 'components/customer/loans/common/loan-automatic-transfer/loan-automatic-transfer.component';
// administration/common
import 'components/administration/common'
// customers
import 'components/dashboard/lookup-customer/lookup-customer.component';
import 'components/dashboard/lookup-customer/lookup-customer-modal.service';
import 'components/dashboard/lookup-customer-group/lookup-customer-group.component';
import 'components/dashboard/new-customer/new-customer.component';
import 'components/customer/common';
import 'components/customer/landing-page/customer-landing-page.component';
import 'components/customer/profile/profile.component';
import 'components/customer/profile/merge/customer-merge-storage.service';
import 'components/customer/profile/merge/customer-merge.component';
import 'components/customer/term-deposits/index';
import 'components/customer/loans/customer-loans.component';
import 'components/customer/loans/edit/customer-loans-edit-amortization.component';
import 'components/customer/loans/edit/customer-loans-edit-information.component';
import 'components/customer/loans/edit/customer-loans-edit-metadata.component';
import 'components/customer/loans/edit/customer-loans-edit-automatic-transfer.component';
import 'components/customer/loans/statement-of-account/customer-loans-statement.component';
import 'components/customer/loans/promissory-note-print/promissory-note-print.component';
import 'components/customer/loans/amortization-schedule-report/amortization-schedule-report.component';
import 'components/customer/loans/loan-payment-report/loan-payment-report.component';
import 'components/customer/loans/disclosure-statement/print-disclosure-statement.component';
import 'components/customer/customer-access';
import 'components/customer/active-customer.service';
import 'components/customer/forex/index';
import 'components/customer/stock/index';
import 'components/customer/layaways/index';

import 'components/customer/group-loans/index.js';
//accounts
import 'components/customer/accounts/accounts.component';
import 'components/customer/accounts/checkbook/checkbook-details.component';

import 'components/customer/pawns/index';

import 'shared/customer-profile-commons/addresses.component';
import 'shared/customer-profile-commons/documents.component';
import 'shared/customer-profile-commons/customer-profile-header.component';
import 'shared/customer-profile-commons/income.component';
import 'shared/customer-profile-commons/external-accounts.component';
import 'shared/customer-profile-commons/individual-data.component';
import 'shared/customer-profile-commons/corporate-data.component';
import 'shared/customer-profile-commons/related-people.component';
// dashboard
import 'components/dashboard/actions/action-list.component';
import 'components/dashboard/actions/view-profile/view-profile.component';
import 'components/dashboard/miscellaneous-transactions/miscellaneous-transactions.component';
import 'components/dashboard/miscellaneous-transactions/common/action-command';
import 'components/dashboard/miscellaneous-transactions/common/currencies-denomination/currencies-denomination.component';
import 'components/dashboard/miscellaneous-transactions/start-the-day/start-the-day.component';
import 'components/dashboard/miscellaneous-transactions/cash-transfer-to-teller/cash-transfer-to-teller.component';
import 'components/dashboard/miscellaneous-transactions/teller-cash-overage/teller-cash-overage.component';
import 'components/dashboard/miscellaneous-transactions/teller-cash-shortage/teller-cash-shortage.component';
import 'components/dashboard/miscellaneous-transactions/cash-to-depository-bank/cash-to-depository-bank.component';
import 'components/dashboard/miscellaneous-transactions/check-list/check-list.component';
import 'components/dashboard/miscellaneous-transactions/cash-withdrawal-from-depository-bank/cash-withdrawal-from-depository-bank.component';
import 'components/dashboard/miscellaneous-transactions/official-receipt/official-receipt.component';
import 'components/dashboard/miscellaneous-transactions/pos-cash-withdrawal/pos-cash-withdrawal.component';
import 'components/dashboard/miscellaneous-transactions/cashier-check-encashment/cashier-check-encashment.component';
import 'components/dashboard/miscellaneous-transactions/close-counters/close-counters.component';
import 'components/dashboard/miscellaneous-transactions/end-the-day/end-the-day.component';
import 'components/dashboard/miscellaneous-transactions/cash-transfer-to-cashier/cash-transfer-to-cashier.component';
import 'components/dashboard/miscellaneous-transactions/check-transfer-to-cashier/check-transfer-to-cashier.component';
import 'components/dashboard/miscellaneous-transactions/misc-cash-disbursement/misc-cash-disbursement.component';
import 'components/dashboard/miscellaneous-transactions/inter-branch-cash-disbursement/inter-branch-cash-disbursement.component';
import 'components/dashboard/miscellaneous-transactions/cashier-cash-overage/cashier-cash-overage.component';
import 'components/dashboard/miscellaneous-transactions/cashier-cash-shortage/cashier-cash-shortage.component';
import 'components/dashboard/miscellaneous-transactions/check-deposit-to-bank/check-deposit-to-bank.component';
import 'components/dashboard/miscellaneous-transactions/cashiers-check-creation/cashiers-check-creation.component';
import 'components/dashboard/miscellaneous-transactions/funds-movement/funds-movement.component';
import 'components/dashboard/miscellaneous-transactions/branch-cash-transfer/branch-cash-transfer.component';
import 'components/dashboard/miscellaneous-transactions/create-interbranch-cashier-check/create-interbranch-cashier-check.component';
import 'components/dashboard/miscellaneous-transactions/encash-interbranch-cashier-check/encash-interbranch-cashier-check.component';
import 'components/dashboard/miscellaneous-transactions';
import 'components/dashboard/history/history-miscellaneous-transactions.component';
import 'components/dashboard/collections/collections.component';
import 'components/dashboard/pawned-items/pawned-items-list.component';
import 'components/dashboard/pawned-items/new-appraisal/new-appraisal.component';
import 'components/dashboard/pawned-items/pawned-item-details-panel/pawned-item-details-panel.component';
import 'components/dashboard/stock-inventory';
import 'components/dashboard/pawn-mass-withdrawal/pawn-mass-withdrawal.component';
import 'components/dashboard/system-health-check/system-health-check.component';
// dashboard.attendance
import 'components/dashboard/attendance/attendance.component'
import 'components/dashboard/attendance/clock-in-out.component'
import 'components/dashboard/attendance/attendance.service'
// inventory
import 'components/inventory/check/checks-list.component';
import 'components/inventory/check/incoming/incoming-checks-list.component';
import 'components/inventory/check/outgoing/outgoing-checks-list.component';
import 'components/inventory/check/outgoing/post-outgoing-check/post-outgoing-check.component';
import 'components/inventory/check/outgoing/batch-register-inward-check/batch-register-inward-check.component';
import 'components/inventory/check/on-us/on-us-checks-list.component';
import 'components/inventory/stop-order/stop-order-list.component';
import 'components/inventory/stop-order/active/active-stop-order-list.component';
// batch
import 'components/batch/memo/batch-memo.component';
import 'components/batch/sss-pension/batch-sss-pension.component';
//report
import 'components/report/report.service';
import 'components/report/report-details.component';
import 'components/report/report-list.component';
import 'components/report/view/report-view.component';
import 'components/report/modal/report-modal.component';
import 'components/report/custom-report/custom-report.component';

import 'components/forex/index';

// import bundles
import 'components/support';
import 'components/administration/security';
// common components
import 'components/common/breadcrumb/breadcrumb.component';
import 'components/common/sub-header/sub-header.component';
import 'components/common/form-section/form-section.component';
import 'components/common/form-section/form-section-field.component';
import 'components/common/day-of-week/day-of-week.component';
import 'components/common/exchange-course-list/exchange-course-list.component';
import 'components/common/multiselect/multiselect.component';
import 'components/common/query-param-guard/query-param-guard.component';
import 'components/common/account-select/account-select.component';
import 'components/common/inline-panel/inline-panel.component';
import 'components/common/branches-list/branches-list.component';
import 'components/common/local-override/local-override.component';
import 'components/common/customer-picker/customer-picker.component';
import 'components/common/customer-picker-paginated/customer-picker-paginated.component';
import 'components/common/charge/charge.component';
import 'components/common/charge/progressive-charge.component';
import 'components/common/charge/custom-fee.component';
import 'components/common/charge/custom-fee-loan-board.component';
import 'components/common/charge/custom-fee-list.component';
import 'components/common/denomination/denomination.component';
import 'components/common/loan/loan-mis-group.component';
import 'components/common/loan/report-approvals-filters.component';
import 'components/common/loan/loan-industry-purpose.component';
import 'components/common/loan/hierarchy-builder.service';
import 'components/common/print/modal-print-preview.component';
import 'components/common/check-form/check-form.component';
import 'components/common/misc-transaction-units/transaction-units.component';
import 'components/common/transaction-history/transaction-history.component';
import 'components/common/ledger-units/ledger-units.component';
import 'components/common/validator/validator.component';
import 'components/common/product-holds/product-holds.component';
import 'components/common/product-locks/product-locks.component';
import 'components/common/product-owners/product-owners.component';
import 'components/common/cash/cash.component';
import 'components/common/date/date.component';
import 'components/common/sql-editor/sql-editor.component';
import 'components/common/health-check-list';
import 'components/common/pawn-tag-select/pawn-tag-select.component';
import 'components/common/denomination-serial-numbers/denomination-serial-numbers.component';
// service
import 'components/service';

import 'components/notification/index'

import _ from 'lodash';

import BigNumber from 'bignumber.js';
import 'daterangepicker';


function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}
// include all libs
requireAll(require.context("./libs", true, /^\.\/.*\.js$/));

// include all styles
requireAll(require.context("../public/css/lib", true, /^\.\/.*(\.css|\.less)$/));
requireAll(require.context("../style", true, /^\.\/.*\.less$/));

// run run services!
module.run((authentication, config, systemPropertyCache, translationCache, formattingService, appVersionService, refreshService, analyticsService) => {
  //Fetch all translations
  translationCache.toObservable().first().subscribe();
  //Turn off error messaging
  BigNumber.config({ERRORS: false});

  systemPropertyCache.toObservable().first().subscribe(properties => {
    const uiCommaSeparator = _.find(properties, {code: 'UI_CASH_COMMA_SEPARATOR'});
    formattingService.setUiCommaSeparator(uiCommaSeparator ? uiCommaSeparator.value === 'TRUE' : false);
  });

  // This is the quasi-main method of the app. It's called after all components have been loaded.
  if (!authentication.isSessionExpired()) {
    removePreloader();
  } else {
    authentication.logout();
  }

  appVersionService.isAppOutdated().then((isOutdated) => {
    if (isOutdated) {
      refreshService.refreshPage({});
    }
  });

})
  .run((cashTransferCheckService, $location) => {
    cashTransferCheckService.toObservable()
      .subscribe(shouldBlock => {
        const actionsPath = '/dashboard/actions';
        if(shouldBlock && $location.path() !== actionsPath) {
          $location.path(actionsPath);
        }
      });
  })
  .run(($rootScope, config) => {
    if(config.devProfile) {
      $rootScope.$on('$routeChangeError', (evt, currentPath, previousPath, error) => {
        console.warn('Route change error on path:', currentPath, 'error:', error);
      });
    }
  });




function removePreloader() {
  $(document).ready(() => {
    $('#preloader').remove();
  });
}
