import module from 'module';
import _ from 'lodash';
import {addAccountLabels} from '../../../general-ledger/common/gl.utils';

import './stock-item-create-layaway-view.style.less';

import templateUrl from './stock-item-create-layaway-view.template.html';
import {BigNumber} from "bignumber.js";

class StockItemCreateLayawayView {
  constructor ($routeParams, $location, pawnItemCache, pawnItemTypeCache, authentication, notification, command,
               confirmationTemplate, actionCategoryCache, systemPropertyCache, glMappingsService, branchService,
               $filter, customerCache, confirmation, http, layawayProductsCache, popup, productService) {
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.pawnItemCache = pawnItemCache;
    this.pawnItemTypeCache = pawnItemTypeCache;
    this.authentication = authentication;
    this.notification = notification;
    this.command = command;
    this.confirmationTemplate = confirmationTemplate;
    this.actionCategoryCache = actionCategoryCache;
    this.systemPropertyCache = systemPropertyCache;
    this.glMappingsService = glMappingsService;
    this.branchService = branchService;
    this.$filter = $filter;
    this.customerCache = customerCache;
    this.confirmation = confirmation;
    this.http = http;
    this.layawayProductsCache = layawayProductsCache;
    this.popup = popup;
    this.productService = productService;
  }

  async $onInit() {

    const [properties, branches, categories, ledgerAccounts, layawayProducts] = await Promise.all([
      this.systemPropertyCache.toPromise(),
      this.branchService.toPromise(),
      this.actionCategoryCache.toPromise(),
      this.glMappingsService.accounts.toPromise(),
      this.layawayProductsCache.toPromise()
    ]);

    this.sellingPrice = this.sumTotalPrice(this.items, 'sellingPrice');

    this.request = {
      itemIds: this.items.map(i => i.id),
      branchId: this.items[0].branchId,
      customerId: null,
      layawayTypeId: null,
      downPaymentAmount: null,
      denominationBundle: null,
    };

    this.resetCalculation();

    this.availableLayawayProducts = layawayProducts.filter(pp => this.productService.availableForSale(pp));

    const ledgerSupportProperty = _.find(properties, {code: 'LEDGER_SUPPORT'});
    this.supportsLedger = ledgerSupportProperty && ledgerSupportProperty.value === 'TRUE';

    // Apply labels to accounts applicable for
    this.ledgerAccounts = addAccountLabels(ledgerAccounts);

    this.categories = categories.filter(cat => cat.actionType === 'CASH_IN' && cat.code === 'SELL_PAWN_ITEM');
    // Select the first and only category
    this.transaction = {
      categoryId: this.categories[0].id
    }
    this.onCategoryChange();
  }

  resetCalculation() {
    this.calculation = null;
  }

  onInputChange() {
    this.resetCalculation();
  }

  async calculate() {
    try {
      this.calculation = await this.http.post('/products/layaways/calculate', {
        ...this.request,
        customerId: this.customer.customerId,
        branchId: this.items[0].branchId,
        itemIds: this.items.map(i => i.id),
      }).toPromise();

      this.onCategoryChange();
    } catch (e) {
      this.popup({
        header: 'Error',
        text: e.errorMessage
      });
    }
  }
  setCustomer(customer) {
    this.customer = customer;
  }

  getTypeLabel(typeId) {
    const type = _.find(this.pawnItemTypes, {id: typeId});
    return type && type.name ? type.name : '-';
  }

  onCategoryChange() {
    this.remarksRequired = false;
    if (this.transaction.categoryId) {
      const category = _.find(this.categories, {id: this.transaction.categoryId});
      this.remarksRequired = category && category.remarksRequired;

      if (!this.supportsLedger) {
        // no need to fill units
        return;
      }

      if (category.ledgerAccountFullCode) {
        const fixedAccount = _.find(this.ledgerAccounts, {fullCode: category.ledgerAccountFullCode});

        this.blockedUnits = [
          {
            accountCode: fixedAccount.fullCode,
            amount: this.request.downPaymentAmount,
            entryType: 'CREDIT'
          }
        ];
      } else {
        this.blockedUnits = [];
      }
    }
  };

  goBack() {
    this.mode = null;
  }

  async create() {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to create following layaway?`,
      details: [
        {label: 'Customer', description: `${this.customer.name} (${this.customer.customerNumber})`},
        {label: 'Transaction category', description: this.categories.find(c => c.id === this.transaction.categoryId).name},
        {label: 'Layaway type', description: this.availableLayawayProducts.find(l => l.id === this.calculation.typeId).productDefinition.productName},
        {label: 'Down payment', description: this.$filter('php')(this.calculation.downPaymentAmount)},
        {label: 'Date of agreement', description: this.$filter('prettyDate')(this.calculation.agreedOn)},
        {label: 'Last installment Date', description: this.$filter('prettyDate')(this.calculation.lastInstallmentDate)},
        {label: 'Expiration Date', description: this.$filter('prettyDate')(this.calculation.expirationDate)},
        {label: 'Forfeiture Date', description: this.$filter('prettyDate')(this.calculation.forfeitureDate)},
        {label: 'Installment amount', description: this.$filter('php')(this.calculation.installmentAmount)}
      ]
    });

    if (!confirmed) {
      return;
    }

    const request = {
      ...this.request,
      customerId: this.customer.customerId,
      units: this.supportsLedger ? this.transaction.units : null
    };

    const response = await this.command.execute('CreateLayaway', request).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.customerCache.layaways(this.customer.customerId).evict();
    this.pawnItemCache.evict();
    this.$location.path(`/customer/${this.customer.customerId}/layaways/`);
  }

  sumTotalPrice(items, property) {
    return items.map(entry => new BigNumber(entry[property]))
      .reduce((a, b) => a.plus(b), new BigNumber(0));
  }
}

module.component('stockItemCreateLayawayView', {
  templateUrl: templateUrl,
  bindings: {
    mode: '=',
    items: '<'
  },
  controller: StockItemCreateLayawayView
});
